body {
  /* background-color: #2e2f38 !important; */
  /* background-color: #333540 !important; */
  background-color: #f2f6ff !important;
  /* color: #fff !important; */
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #777;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

pre {
  /*background-color: ghostwhite;*/
  /*border: 1px solid silver;*/
  /*padding: 10px 20px;*/
  /*margin: 20px; */
}

.json-key {
  color: brown;
}
.json-value {
  color: navy;
}
.json-string {
  color: olive;
}

textarea {
    border: none !important;
    overflow: auto !important;
    outline: none !important;

    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;

    resize: none !important; /*remove the resize handle on the bottom right*/

    line-height: 1.30 !important;

    white-space:nowrap;
}
.__json-pretty__ {
  /* background: #edf2f7 !important; */
  background: #2e2f38 !important;
}

.__json-string__{
  /* color: #3334e6 !important; */
}